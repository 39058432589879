<template>
    <div class="table-view industries-table-view">
        <!--
            <div class="input-group">
                <input v-model.trim="search" @input="debounceGetEvents" type="text" name="search" :placeholder="$t('search')">
            </div>
        -->

        <div class="action-header">
            <h1>{{ $t('Events') }}</h1>
            <div class="actions">
                <button type="button" class="btn btn-grey" @click="getEvents">
                    {{ $t('Refresh') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.948" height="20" viewBox="0 0 20.948 20">
                        <g>
                            <g>
                                <path d="M10 13a8.37 8.37 0 0 1 7.528 4.47 3 3 0 0 1-2.58 4.53h-9.9a3 3 0 0 1-2.581-4.53A8.37 8.37 0 0 1 10 13zm0 2a6.383 6.383 0 0 0-5.808 3.49 1 1 0 0 0 .86 1.51h9.9a1 1 0 0 0 .86-1.51A6.383 6.383 0 0 0 10 15zm9-7a1 1 0 0 1 .993.883L20 9v2h2a1 1 0 0 1 .117 1.993L22 13h-2v2a1 1 0 0 1-1.993.117L18 15v-2h-2a1 1 0 0 1-.117-1.993L16 11h2V9a1 1 0 0 1 1-1zm-8.833-6a5 5 0 0 1 0 10H10a5 5 0 0 1 0-10h.167zm-.083 2h.083a3 3 0 0 0-.083 6H10a3 3 0 0 0 .083-6z" transform="translate(-820.052 268) translate(818 -270)"/>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">{{ $t("Title") }}</th>
                    <th scope="col">{{ $t("Workflow") }}</th>
                    <th scope="col">{{ $t("Notification") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="event in events" :key="event.id">
                    <td>{{ event.title }}</td>
                    <td>
                        <select class="form-control mb-0" v-model="event.workflow_id"
                                @change="updateEvent(event, $event.target.value)">
                            <option value="">-</option>
                            <option :value="workflow.id" v-for="workflow in workflows" :key="workflow.id">{{
                                    workflow.title
                                }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control mb-0" v-model="event.notification_id"
                                @change="updateEvent(event, $event.target.value)">
                            <option value="">-</option>
                            <option :value="notification.id" v-for="notification in notifications" :key="notification.id">
                                {{ notification.title }}
                            </option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {eventService} from '@/services/settings/eventService'
import {workflowService} from '@/services/settings/workflowService'
import {notificationService} from '@/services/settings/notificationService'
import HeaderSearch from '@/mixins/HeaderSearch';

export default {
    name: "ViewAllEvents",
    mixins: [ HeaderSearch ],
    data() {
        return {
            events: [],
            workflows: [],
            notifications: [],

            search: '',
            page: '',
            showAddComponent: false,
        }
    },
    mounted() {
        this.getNotifications();
        this.getWorkflows();
        this.getEvents()
    },
    methods: {
        searchFromHeader(keyword) {
            this.search = keyword;
            this.getEvents();
        },
        getNotifications() {
            notificationService.index({}).then(response => {
                this.notifications = response.data.data
            })
        },
        getWorkflows() {
            workflowService.index({}).then(response => {
                this.workflows = response.data.data
            })
        },
        getEvents() {
            eventService.index({
                "q": this.search,
                "page": this.page
            }).then(response => {
                this.events = response.data.data
            })
        },
        updateEvent(event, value) {
            eventService.update(event.id, event).then(response => {
                this.getEvents();
            })
        },
    }
}
</script>