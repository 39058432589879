import axios from '@/axios';

class EventService {
    index(query) {
        return axios.get(`/settings/events`, {
            params: query
        });
    }
    update(id, data) {
        return axios.put(`/settings/events/${id}`, data);
    }
}

export const eventService = new EventService();